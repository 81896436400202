import React from "react"
import { Layout,  Parallax, DoubleCTASection } from "@components/layout"

import { Breadcrumbs } from "@components/shared"
import { SingleOption, Avatar } from "@components/standard"

import {
  page__wrapper,
  page__content,
  about__header,
  about__points,
  about__points__title,
  about__description,
  about__header__wrapper,
  about__wave,
  about__icon,
} from "./styles/about.module.scss"

const options = [
  { label: "8 lat ", content: "pracuję jako Frontend Developer" },
  {
    label: "80+ osób ",
    content: "samodzielnie wprowadziłem do świata Frontendu	",
  },
  {
    label: "Wymyśliłem inicjatywę",
    content: "Programmers Only, która zrzesza koderów szukających współnych projektów",
    cta_label: "Grupa Programmers Only",
    cta_link: "https://www.facebook.com/groups/programmersonlyapp",
  },
  {
    label: "Koduję",
    content:
      "bo to zajebiście lubię",
  },
  {
    label: "stworzyłem 300+ zadań",
    content:
      "z HTML, CSS, JavaScript, React, Jest i innych bibliotek frontendowych (ciągle tworzę nowe)	",
  },
  { label: "5 lat", content: "zakochany w React	" },
  {
    label: "1+ milion ",
    content: "wymienionych ze studentami wiadomości na slacku i messengerze",
  },
  {
    label: "Lubię uczyć ",
    content: "i pomagać w rozwijaniu się moim podopiecznym	",
  },
  {
    label: "od 3 lat ",
    content: "prowadzę mentorską szkołę programowania Localhost Academy",
    cta_label: "Strona akademii",
    cta_link: "https://academy.localhost-group.com/",
  },
  {
    label: "Mentor	",
    content: "na Facebooku, współpracowałem z Infoshare i Future Collars	",
  },
  {
    label: "20 tysięcy ",
    content: "godzin spędzonych ze studentami i kursantami ",
  },
]

const Wave = () => {
  return (
    <div className={about__wave}>
      <svg viewBox="0 0 1200.88 400">
        <path
          d="M1200,400c-134.35-137.81-310-62.19-769.68-15.42S0,0,0,0V400Z"
          transform="translate(0.88)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}

const About = ({ pageContext }) => {
  const { page } = pageContext
  return (
    <Layout
      animation={false}
      seo={pageContext.page.seo}
      siteMetaData={pageContext.page.siteMetaData}
    >
      <div className={about__header__wrapper}>
        <Parallax height={500} image={page.featuredImage} title=
        {"UKOŃCZENIE DOWOLNEGO KURSU PROGRAMOWANIA TO DOPIERO POCZĄTEK W KIERUNKU PIERWSZEJ PRACY, TERAZ MUSISZ TYLKO NAUCZYĆ SIĘ DOBRZE PISAĆ KOD"}>
          <Wave />
        </Parallax>
      </div>
      <div className={page__wrapper}>
        <div className={page__content}>
          <Breadcrumbs
            elements={[
              {
                label: page.title,
                url: `/${page.slug}/`,
              },
            ]}
          />
          <div className={about__header}>
            <h3>
              Przemek Jóźwiakowski | Github:{" "}
              <a href="https://github.com/Przemocny">Przemocny</a>
            </h3>

            <div className={about__description}>
              <Avatar />
              <h6>
                CHCĘ BYĆ TAKIM MENTOREM JAKIEGO SAM CHCIAŁBYM MIEĆ, JAK
                ZACZYNAŁEM SWOJĄ PRZYGODĘ Z PROGRAMOWANIEM PRAWIE 10 LAT TEMU
              </h6>
            </div>
          </div>
          <div className={about__points}>
            <h6 className={about__points__title}>O mnie w punktach:</h6>
            <div className={about__icon}></div>
            <div className="row">
              <div className="col">
                {options.map((el, k) => {
                  return <SingleOption {...el} key={k} />
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DoubleCTASection
        label1={"Czym się zajmuję: Legion Gladiatorów JavaScriptu"}
        link1={"https://google.com"}
        label2={"Czym się zajmuję: Zbiory zadań Gladiatorów JavaScriptu"}
      />
    </Layout>
  )
}
export default About
